// import { images } from "./notebook-app/images";

export const featuredArticles = [
  {
    title: "Blockchain: Understanding basics",
    description:
      "In this article let's try to demystify and understand the basics of the blockchain infrastructure and see how it contributes to the web3 ecosystem.",
    tag_list: ["blockchain", "bitcoin", "web3", "ethereum"],
    // github_url: "https://github.com/repo,
    canonical_url:
      "https://blog.vishnuprasaath.dev/blockchain-understanding-basics",
    external: true,
    isNew: true,
    // live: "https://URL",
    published_at: "2023-04-27T13:05:31Z",
    reading_time_minutes: "3 min read",
    // views: "721"
  },
  {
    title: "Dockerize React app for dev and prod",
    description:
      "Use docker to containerize frontend react app for development and production environment",
    tag_list: ["react", "docker", "container"],
    // github_url: "https://github.com/repo",
    canonical_url:
      "https://blog.vishnuprasaath.dev/dockerize-react-app-for-dev-and-prod",
    external: true,
    isNew: false,
    published_at: "2023-04-11T13:05:31Z",
    reading_time_minutes: "3 min read",
    // views: "721"
  },
];
