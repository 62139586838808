// import gitHub from "../assets/images/repositories/GitHubProject.png"; //Default git logo
import reactSocial from "../assets/images/repositories/react_social_design-min.jpg";
import reactEcomm from "../assets/images/repositories/react_ecomm_design2-min.jpg";
import facBuc from "../assets/images/repositories/facBuc.webp";
import sedna from "../assets/images/repositories/sedna.jpg";
import homeBot from "../assets/images/repositories/HomeBot.png";
import kinect from "../assets/images/repositories/kinect.jpg";
import crypto from "../assets/images/repositories/crypto.png";

export const repositories = [
  {
    title: "React Social",
    description: "Minimal social media web app",
    cover: reactSocial,
    technologies: ["React", "SCSS", "NodeJs"],
    url: "https://github.com/pvishnuprasaath/react-social-v1",
    live: "https://github.com/pvishnuprasaath/react-social-v1",
    blurHash: "L25#he^nryxc^-w$V{V_56bqx[M{",
    stars: "12",
    fork: "9",
  },
  {
    title: "React E-commerce",
    description:
      "Minimal e-commerce web app for fashion shopping built by using React, Redux and Strapi.",
    cover: reactEcomm,
    technologies: ["React", "Redux", "Strapi"],
    url: "https://github.com/pvishnuprasaath/react-ecommerce-v1",
    live: "https://github.com/pvishnuprasaath/react-ecommerce-v1",
    blurHash: "L25#he^nryxc^-w$V{V_56bqx[M{",
    stars: "2",
    fork: "0",
  },
  {
    title: "Sedna",
    description:
      "Vanilla JS frontend web app built to showcase Styling and design",
    cover: sedna,
    technologies: ["VanillaJs", "CSS"],
    url: "https://github.com/pvishnuprasaath/Sedna",
    live: "https://github.com/pvishnuprasaath/Sedna",
    blurHash: "LMOWg4wIS$WBH?sAkCR*?^bcnNoy",
    stars: "2",
    fork: "0",
  },
  {
    title: "BucketCrypt-API",
    description:
      "This is a notebook app which will help you to crate notes for your daily work.",
    cover: crypto,
    technologies: ["NodeJs", "Cryptography", "REST"],
    url: "https://github.com/pvishnuprasaath/Bucketcrypt-API",
    live: "https://github.com/pvishnuprasaath/Bucketcrypt-API",
    blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
    stars: "2",
    fork: "0",
  },
  {
    title: "FacBuc",
    description:
      "Mobile app built with Ionic framework for displaying data signals broadcasted by an IOT device",
    cover: facBuc,
    technologies: ["Ionic", "Android", "Bootstrap"],
    url: "https://github.com/pvishnuprasaath/FacBuc",
    live: "https://github.com/pvishnuprasaath/FacBuc",
    blurHash: "L35O{d~XeoMyDhRPxv%Ms=xunmRQ",
    stars: "2",
    fork: "0",
  },
  {
    title: "HomeBot",
    description:
      "Chatbot for home automation built with API.AI, Node webhook and integrated into Google Assistant",
    technologies: ["NodeJs", "GoogleAssistant", "WebHooks"],
    cover: homeBot,
    url: "https://github.com/pvishnuprasaath/BucketBot",
    live: "https://github.com/pvishnuprasaath/BucketBot",
    blurHash: "LMMaw^IV~pxu00%LRjNGAIIVadt6",
    stars: "1",
    fork: "0",
  },
  {
    title: "Kinect Home",
    description:
      "Utilizing the depth sensor and spatial computation possibility of Microsoft Kinect Sensor, built a UWP app in C# to recognize human hand gestures and kick-of callback triggers to Node Express API endpoints. The objective of the project was to utilize ML models for gesture recognition to automate simple tasks for college professors",
    cover: kinect,
    technologies: ["NodeJs", "C#", "ML"],
    url: "https://github.com/pvishnuprasaath/Gesture-Detection--Kinect",
    live: "https://github.com/pvishnuprasaath/Gesture-Detection--Kinect",
    blurHash: "LSN1AcH?~Wtl00={M{NG0eIoj]xa",
    stars: "2",
    fork: "1",
  },
  // {
  //   title: "dummy",
  //   description: "A portfolio template.",
  //   cover: cardportfolio,
  //   technologies: ["react", "typescript", "chakraUI"],
  //   url: "url",
  //   live: "url",
  //   blurHash: "L15#hiax00og^Uf65Nj]oPfkWRf6",
  //   stars: "9",
  //   fork: "2",
  // },
  // {
  //   title: "dummy",
  //   description: "dummy",
  //   cover: quickstart,
  //   technologies: ["rails", "tailwindCss"],
  //   url: "url",
  //   live: "dummy",
  //   blurHash: "LLPGmg?wRPS~D%D%ozxa00?G-;R%",
  //   stars: "8",
  // },
  // {
  //   title: "dummy",
  //   description: "dummy",
  //   cover: blog3,
  //   technologies: ["rails", "tailwindCss"],
  //   url: "url",
  //   live: "url",
  //   blurHash: "L6O;6=00M|og00_4_4s:E9-oxVoL",
  //   stars: "4",
  // },
  // {
  //   title: "dummy",
  //   description:
  //     "dummy",
  //   cover: imgGallery,
  //   technologies: ["react", "tailwindCss"],
  //   url: "url",
  //   blurHash: "LMOWg4wIS$WBH?sAkCR*?^bcnNoy",
  //   stars: "3",
  // },
  // {
  //   title: "dummy",
  //   description: "dummy",
  //   technologies: ["ruby", "rails"],
  //   url: "url",
  //   blurHash: "L4S~x5xb~q$*~WR6MykCx^Vt4TtP",
  //   stars: "4",
  //   fork: "1",
  // },
];
