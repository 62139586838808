import React from "react";
import {
  FaGithub,
  FaDev,
  FaLinkedin,
  // FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { SiLeetcode, SiHashnode } from "react-icons/si";
import { FiMail } from "react-icons/fi";

const siteConfig = {
  copyright: `Copyright © ${new Date().getFullYear()} Vishnu Prasaath. All Rights Reserved.`,
  // hashnodeBaseUrl: "https://blog.vishnuprasaath.dev/",
  author: {
    name: "Vishnu Prasaath",
    accounts: [
      {
        url: "https://github.com/pvishnuprasaath",
        label: "Github Account",
        type: "gray",
        icon: <FaGithub />,
      },
      {
        url: "https://linkedin.com/in/pvishnuprasaath",
        label: "LinkedIn Account",
        type: "linkedin",
        icon: <FaLinkedin />,
      },
      {
        url: "https://www.instagram.com/vishnu.prasaath/",
        label: "Instagram Account",
        type: "orange",
        icon: <FaInstagram />,
      },
      {
        url: "https://blog.vishnuprasaath.dev/",
        label: "Hashnode Account",
        type: "blue",
        icon: <SiHashnode />,
      },

      {
        url: "https://dev.to/pvishnuprasaath",
        label: "Dev Account",
        type: "gray",
        icon: <FaDev />,
      },

      {
        url: "https://leetcode.com/pvishnuprasaath/",
        label: "LeetCode Account",
        type: "yellow",
        icon: <SiLeetcode />,
      },
      // {
      //   url: "https://twitter.com/pvishnuprasaath",
      //   label: "Twitter Account",
      //   type: "twitter",
      //   icon: <FaTwitter />,
      // },
      {
        url: "mailto:pvishnuprasaath@gmail.com",
        label: "Mail Vishnu",
        type: "gray",
        icon: <FiMail />,
      },
    ],
  },
};

export default siteConfig;
